import { toJS } from 'mobx';
import { castToSnapshot, getRoot } from 'mobx-state-tree';

import { createSubdomainUrl } from '@webapp/common/lib/utils';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';
import type { ISurveyQuestionGroupModel } from '@webapp/common/resources/mst-survey/question_group';
import type { ISurveyQuestionLogicModel } from '@webapp/common/resources/mst-survey/question_logic';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';
import type { ISurveyBundleModel } from '@webapp/common/resources/mst-survey/survey/bundle';
import { SurveyBundleFinishType, SurveyBundleType } from '@webapp/common/resources/mst-survey/survey/bundle';
import {
    LogicAction,
    LogicBoolFunc,
    LogicTransition,
    LogicTransitionType,
    LogicType,
    QuestionType
} from '@webapp/common/resources/survey';

const getGroupsValues = (groups: Array<ISurveyQuestionGroupModel>): Array<string | number> =>
    groups.reduce((acc, { responses }) => {
        const entries = [...responses.values()].map(({ response: { value } }) => value);

        if (entries.length > 0) {
            acc.push(entries);
        }

        return acc;
    }, []);

const TEXT_FINISH = new Set([
    LogicTransition.PERSONAL_COMPLETE,
    LogicTransition.DISQUAL,
    LogicTransition.PAGES,
    LogicTransition.QUESTIONS
]);

const createLastBundle = ({
    completeText,
    disqualText,
    toSurveyUrl,
    toWebsite,
    transition,
    transitionType
}): ISurveyBundleModel => {
    // if (!TEXT_FINISH.has(transition)) return null;

    const lastBundle: ISurveyBundleModel = castToSnapshot({
        type: null,
        finishType: (() => {
            switch (transition) {
                case LogicTransition.PERSONAL_COMPLETE:
                    return SurveyBundleFinishType.STOP;
                case LogicTransition.DISQUAL:
                    return SurveyBundleFinishType.DISQUAL;
                default:
                    return SurveyBundleFinishType.STOP; // TODO FIXME not used
            }
        })(),
        random: false,
        data: {
            questions: []
        }
    });

    if (!TEXT_FINISH.has(transition)) return lastBundle;

    switch (transitionType) {
        case LogicTransitionType.TEXT:
            lastBundle.type = SurveyBundleType.TEXT;
            lastBundle.text = [
                LogicTransition.PERSONAL_COMPLETE,
                LogicTransition.PAGES,
                LogicTransition.QUESTIONS
            ].includes(transition)
                ? completeText
                : disqualText;
            break;
        case LogicTransitionType.TO_SURVEY:
            lastBundle.type = SurveyBundleType.REDIRECT_SURVEY;
            lastBundle.redirect = createSubdomainUrl(toSurveyUrl);
            break;
        case LogicTransitionType.REDIRECT_TO_WEBSITE:
            lastBundle.type = SurveyBundleType.REDIRECT_URL;
            lastBundle.redirect = toWebsite;
            break;
    }
    return lastBundle;
};

const logicMatch = (
    logic: ISurveyQuestionLogicModel,
    groups: Array<ISurveyQuestionGroupModel>,
    answers: Array<ISurveyQuestionAnswerModel>,
    response: ISurveyQuestionResponseModel,
    questionType: QuestionType,
    quotaByQuestionId: Record<number, boolean>
): boolean => {
    const { boolFunc, params, type } = logic;
    const notScale = questionType !== QuestionType.SCALE; // TODO fix backend, dont use groups
    const withGroups = Boolean(notScale && groups && groups.length > 0);
    const withAnswers = Boolean(notScale && answers && answers.length > 0);
    const variantsIds = params.reduce((acc, { id }) => ((acc[id] = true), acc), {}); // TODO use Set
    const answersById = answers.reduce((acc, a) => ((acc[a.id] = a), acc), {});
    let satisfied = false;

    switch (type) {
        case LogicType.TYPE_QUESTION_QUOTA: {
            let isSomeOfAnswersSelected = false;
            if (questionType === QuestionType.MATRIX_RATING) {
                isSomeOfAnswersSelected = Object.keys(variantsIds).some((id) => {
                    const [_qId, rating, answerId] = id.toString().split('-');
                    return answersById[answerId]?.response.value.toString() === rating;
                });
            } else if (
                questionType === QuestionType.MATRIX_SINGLE_ANSWER ||
                questionType === QuestionType.MATRIX_FEW_ANSWERS
            ) {
                isSomeOfAnswersSelected = Object.keys(variantsIds).some((id) => {
                    const [_qId, groupId, answerId] = id.toString().split('-');
                    return !!groups.find((gr) => gr.id.toString() === groupId)?.responses.get(answerId)?.response.value;
                });
            } else if (withGroups) {
                const values = getGroupsValues(groups);
                isSomeOfAnswersSelected = values.some((answerId) => variantsIds[answerId]);
            } else if (withAnswers) {
                isSomeOfAnswersSelected = answers.some(({ id, response: { value } }) => !!value && variantsIds[id]);
            } else {
                isSomeOfAnswersSelected = !!variantsIds[response.value as number];
            }
            satisfied = quotaByQuestionId[logic.id] === false && isSomeOfAnswersSelected;
            break;
        }
        case LogicType.SELECT_VARIANTS:
            switch (boolFunc) {
                case LogicBoolFunc.AND:
                    if (questionType === QuestionType.MATRIX_RATING) {
                        const groupedByRowId: { [key: string]: Array<{ rowId: string; rating: string }> } = Object.keys(
                            variantsIds
                        ).reduce((acc, id) => {
                            const [_qId, rating, rowId] = id.toString().split('-');
                            if (!acc[rowId]) {
                                acc[rowId] = [{ rowId, rating }];
                            } else {
                                acc[rowId].push({ rowId, rating });
                            }
                            return acc;
                        }, {});
                        satisfied = Object.keys(groupedByRowId).every((rowId) => {
                            const rowAnswers = groupedByRowId[rowId];
                            return rowAnswers.some(
                                (rowAns) => answersById[rowId]?.response?.value?.toString() === rowAns.rating
                            );
                        });
                    } else if (
                        questionType === QuestionType.MATRIX_SINGLE_ANSWER ||
                        questionType === QuestionType.MATRIX_FEW_ANSWERS
                    ) {
                        const groupedByRowId: { [key: string]: Array<{ rowId: string; groupId: string }> } =
                            Object.keys(variantsIds).reduce((acc, id) => {
                                const [_qId, groupId, rowId] = id.toString().split('-');
                                if (!acc[rowId]) {
                                    acc[rowId] = [{ rowId, groupId }];
                                } else {
                                    acc[rowId].push({ rowId, groupId });
                                }
                                return acc;
                            }, {});
                        satisfied = Object.keys(groupedByRowId).every((rowId) => {
                            const rowAnswers = groupedByRowId[rowId];
                            return rowAnswers.some(
                                (rowAns) =>
                                    !!groups
                                        .find((gr) => gr.id.toString() === rowAns.groupId)
                                        ?.responses?.get(rowAns.rowId)?.response.value
                            );
                        });
                    } else if (withGroups) {
                        // const values = getGroupsValues(groups);
                        // satisfied = values.some((answerId) => variantsIds[answerId]);
                    } else if (withAnswers) {
                        satisfied = Object.keys(variantsIds).every((id) => !!answersById[id]?.response.value);
                    }
                    break;
                case LogicBoolFunc.OR:
                    if (questionType === QuestionType.MATRIX_RATING) {
                        satisfied = Object.keys(variantsIds).some((id) => {
                            const [_qId, rating, answerId] = id.toString().split('-');
                            return answersById[answerId]?.response.value.toString() === rating;
                        });
                    } else if (
                        questionType === QuestionType.MATRIX_SINGLE_ANSWER ||
                        questionType === QuestionType.MATRIX_FEW_ANSWERS
                    ) {
                        satisfied = Object.keys(variantsIds).some((id) => {
                            const [_qId, groupId, answerId] = id.toString().split('-');
                            return !!groups.find((gr) => gr.id.toString() === groupId)?.responses.get(answerId)
                                ?.response.value;
                        });
                    } else if (withGroups) {
                        const values = getGroupsValues(groups);
                        satisfied = values.some((answerId) => variantsIds[answerId]);
                    } else if (withAnswers) {
                        satisfied = answers.some(({ id, response: { value } }) => !!value && variantsIds[id]);
                    } else {
                        satisfied = !!variantsIds[response.value as number];
                    }
                    break;
            }

            break;
        case LogicType.NOT_SELECT_VARIANTS:
            switch (boolFunc) {
                case LogicBoolFunc.AND:
                    if (withGroups) {
                        // const values = getGroupsValues(groups);
                        // satisfied = !values.every((answerId) => variantsIds[answerId]);
                    } else if (withAnswers) {
                        satisfied = Object.keys(variantsIds).every((id) => !answersById[id]?.response?.value);
                    } else {
                    }

                    break;
                case LogicBoolFunc.OR:
                    if (withGroups) {
                        const values = getGroupsValues(groups);
                        satisfied = !values.every((answerId) => variantsIds[answerId]);
                    } else if (withAnswers) {
                        satisfied = answers.some(({ id, response: { value } }) => variantsIds[id] && !value);
                        // satisfied = !answers.every(
                        //     ({ id, response: { value } }) => (variantsIds[id] && !!value) || !variantsIds[id]
                        // );
                    } else {
                        satisfied = !variantsIds[response.value as number];
                    }

                    break;
            }

            break;
        case LogicType.SKIP:
            if (!response.value) {
                satisfied = true;
            }

            if (withAnswers) {
                satisfied = answers.every(({ response: { value } }) => !value);
            }

            if (withGroups) {
                satisfied = groups.every(({ responses }) => {
                    let empty = true;

                    for (const [, { response }] of responses) {
                        if (response.value) {
                            empty = false;
                            break;
                        }
                    }

                    return empty;
                });
            }

            break;
        case LogicType.ALWAYS:
            satisfied = true;

            break;
    }

    return satisfied;
};

const adjustQuestions = (bundles: Array<ISurveyBundleModel>, shownByLogic: boolean): void => {
    let questionOrder = 0;
    bundles.forEach(({ questions }) => {
        questions.forEach((q) => {
            q.setShownByLogic(shownByLogic);
            if (q.type !== QuestionType.TEXT_BLOCK) {
                q.setOrder(++questionOrder);
            }
        });
    });
};

export const filterBundlesQuestionsByLogic = (bundles: Array<ISurveyBundleModel>): Array<ISurveyBundleModel> => {
    let execLogic: ISurveyQuestionLogicModel;
    const questionsLogicMatches: Array<ISurveyQuestionLogicModel> = [];
    const pagesLogicMatches: Array<ISurveyQuestionLogicModel> = [];
    const hideQuestionsLogicMatches: Array<ISurveyQuestionLogicModel> = [];
    const hidePagesLogicMatches: Array<ISurveyQuestionLogicModel> = [];

    adjustQuestions(bundles, false);
    const root: any = getRoot(bundles[0].questions[0]);
    const quotaByQuestionId = root.survey.quotaStatusByQuestionId.reduce(
        (acc, item) => ({ ...acc, [item.questionId]: item.quotaStatus }),
        {}
    );

    const newBundles = bundles.reduce((acc, bundle) => {
        switch (execLogic?.transition) {
            case LogicTransition.PAGE:
                if (execLogic.toPage !== bundle.page.id) {
                    return acc;
                }
                execLogic = null;
                break;
            case LogicTransition.DISQUAL:
            case LogicTransition.PERSONAL_COMPLETE:
            case LogicTransition.REGULAR_COMPLETE:
                return acc;
        }

        bundle.questions = castToSnapshot(
            bundle.questions.reduce<Array<ISurveyQuestionModel>>((questions, question) => {
                const { answers, groups, id, logics, response, type } = question;
                if (
                    pagesLogicMatches.length > 0 &&
                    pagesLogicMatches.some((pagesLogic) => !pagesLogic.toPages.includes(bundle.page.id))
                ) {
                    return questions;
                }
                if (
                    questionsLogicMatches.length > 0 &&
                    questionsLogicMatches.some((questionsLogic) => !questionsLogic.toQuestions.includes(id))
                ) {
                    return questions;
                }
                if (
                    hidePagesLogicMatches.length > 0 &&
                    hidePagesLogicMatches.some((pagesLogic) => pagesLogic.toPages.includes(bundle.page.id))
                ) {
                    return questions;
                }
                if (
                    hideQuestionsLogicMatches.length > 0 &&
                    hideQuestionsLogicMatches.some((questionsLogic) => questionsLogic.toQuestions.includes(id))
                ) {
                    return questions;
                }
                if (execLogic) {
                    switch (execLogic.transition) {
                        case LogicTransition.DISQUAL:
                        case LogicTransition.PERSONAL_COMPLETE:
                        case LogicTransition.REGULAR_COMPLETE:
                            return questions;
                        case LogicTransition.PAGE:
                            if (execLogic.toPage !== bundle.page.id) {
                                return questions;
                            }

                            execLogic = null;

                            break;
                        case LogicTransition.QUESTION:
                            if (execLogic.toQuestion !== id) {
                                return questions;
                            }

                            execLogic = null;

                            break;
                        case LogicTransition.QUESTIONS:
                            questionsLogicMatches.push(execLogic);
                            if (!execLogic.toQuestions.includes(id)) {
                                return questions;
                            }

                            execLogic = null;

                            break;
                        case LogicTransition.PAGES:
                            pagesLogicMatches.push(execLogic);
                            if (!execLogic.toPages.includes(bundle.page.id)) {
                                return questions;
                            }

                            execLogic = null;

                            break;
                    }
                }

                if (!bundle.random) {
                    const sortedLogics = logics.slice().sort((a, b) => {
                        const isAOneOf = [LogicTransition.QUESTIONS, LogicTransition.PAGES].includes(a.transition);
                        const isBOneOf = [LogicTransition.QUESTIONS, LogicTransition.PAGES].includes(b.transition);
                        if (isAOneOf && isBOneOf) {
                            return 0;
                        }
                        if (isAOneOf) {
                            return 1;
                        }
                        if (isBOneOf) {
                            return -1;
                        }
                        return 0;
                    });
                    const matchedLogics = sortedLogics.filter((logic) =>
                        logicMatch(logic, groups, answers, response, type, quotaByQuestionId)
                    );
                    const matchedPagesLogics = matchedLogics.filter(
                        (l) => l.transition === LogicTransition.PAGES && l.action !== LogicAction.HIDE
                    );
                    const matchedQuestionsLogics = matchedLogics.filter(
                        (l) => l.transition === LogicTransition.QUESTIONS && l.action !== LogicAction.HIDE
                    );
                    const hideMatchedPagesLogics = matchedLogics.filter(
                        (l) => l.transition === LogicTransition.PAGES && l.action === LogicAction.HIDE
                    );
                    const hideMatchedQuestionsLogics = matchedLogics.filter(
                        (l) => l.transition === LogicTransition.QUESTIONS && l.action === LogicAction.HIDE
                    );
                    if (
                        matchedPagesLogics.length ||
                        matchedQuestionsLogics.length ||
                        hideMatchedPagesLogics.length ||
                        hideMatchedQuestionsLogics.length
                    ) {
                        if (matchedPagesLogics.length || matchedQuestionsLogics.length) {
                            execLogic = null;
                            if (matchedPagesLogics.length) {
                                const lastPagesLogic = toJS(matchedPagesLogics[matchedPagesLogics.length - 1]);
                                const commonToPages = matchedPagesLogics
                                    .slice(0, -1)
                                    .reduce((acc, item) => acc.concat(item.toPages), []);
                                commonToPages.map((el) => lastPagesLogic.toPages.push(el));
                                pagesLogicMatches.push(lastPagesLogic);
                            }
                            if (matchedQuestionsLogics.length) {
                                const lastQuestionsLogic = toJS(
                                    matchedQuestionsLogics[matchedQuestionsLogics.length - 1]
                                );
                                const commonToQuestions = matchedQuestionsLogics
                                    .slice(0, -1)
                                    .reduce((acc, item) => acc.concat(item.toQuestions), []);
                                commonToQuestions.map((el) => lastQuestionsLogic.toQuestions.push(el));
                                questionsLogicMatches.push(lastQuestionsLogic);
                            }
                        }
                        if (hideMatchedPagesLogics.length || hideMatchedQuestionsLogics.length) {
                            execLogic = null;
                            if (hideMatchedPagesLogics.length) {
                                const lastPagesLogic = toJS(hideMatchedPagesLogics[hideMatchedPagesLogics.length - 1]);
                                const commonToPages = hideMatchedPagesLogics
                                    .slice(0, -1)
                                    .reduce((acc, item) => acc.concat(item.toPages), []);
                                commonToPages.map((el) => lastPagesLogic.toPages.push(el));
                                hidePagesLogicMatches.push(lastPagesLogic);
                            }
                            if (hideMatchedQuestionsLogics.length) {
                                const lastQuestionsLogic = toJS(
                                    hideMatchedQuestionsLogics[hideMatchedQuestionsLogics.length - 1]
                                );
                                const commonToQuestions = hideMatchedQuestionsLogics
                                    .slice(0, -1)
                                    .reduce((acc, item) => acc.concat(item.toQuestions), []);
                                commonToQuestions.map((el) => lastQuestionsLogic.toQuestions.push(el));
                                hideQuestionsLogicMatches.push(lastQuestionsLogic);
                            }
                        }
                    } else {
                        for (const logic of sortedLogics) {
                            /*
                        TODO disabled, take last to satisfy compliance with legacy system
                        if (execLogic) break; // already found
                        */

                            if (logicMatch(logic, groups, answers, response, type, quotaByQuestionId)) {
                                execLogic = logic;
                            }
                        }
                    }
                }

                questions.push(question);

                return questions;
            }, [])
        );

        if (bundle.questions.length > 0) {
            acc.push(bundle);
        }

        return acc;
    }, []);

    adjustQuestions(newBundles, true);

    let lastBundle = execLogic && createLastBundle(execLogic);
    if ((!lastBundle && questionsLogicMatches.length > 0) || pagesLogicMatches.length > 0) {
        const lastMatch =
            pagesLogicMatches[pagesLogicMatches.length - 1] || questionsLogicMatches[questionsLogicMatches.length - 1];
        lastBundle = lastMatch && createLastBundle(lastMatch);
    }

    if (lastBundle) {
        newBundles.push(lastBundle);
    }

    return newBundles;
};
