import { ApiRespCode } from './api';

type RequestDelayFunction = (attempt: number, error: Error | null, response: Response | null) => number;

export interface RequestInitWithRetry extends RequestInit {
    retries?: number;
    retryDelay?: number | RequestDelayFunction;
    log?: boolean;
}

const defaultOptions: RequestInitWithRetry = {
    retries: 3,
    retryDelay: 1000,
    log: false
};

let pendingRequests = 0;
export const hasPendingRequests = (): boolean => pendingRequests > 0;

export const getFormattedParams = (params: Record<string, any>) => {
    let formattedParams = '';

    if (params) {
        const newParams = new URLSearchParams();

        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                newParams.append(key, value);
            }
        });

        formattedParams = `?${newParams}`;
    }

    return formattedParams;
};

export const ttFetch = (
    options: Partial<RequestInitWithRetry> = {}
): ((url: string, input?: RequestInitWithRetry) => Promise<Response | JsonValue>) => {
    const { headers, keepalive, method, retries, retryDelay } = Object.assign({}, defaultOptions, options);

    return (url: string, input) => {
        const form = input.body instanceof FormData;

        input = {
            method,
            headers: Object.assign({}, headers, input.headers),
            body: form ? input.body : JSON.stringify(input.body),
            keepalive
        };

        if (form) {
            delete input.headers['Content-Type'];
        }

        return new Promise((res, rej) => {
            const wrappedFetch = (attempt: number): void => {
                pendingRequests++;

                try {
                    fetch(url, input)
                        .then(async (response) => {
                            const r = response.clone();
                            let body = null;
                            let srvCode = null;
                            let status = null;

                            try {
                                body = await r.json();
                                srvCode = body?.code;
                                status = r?.status;
                            } catch (e) {
                                console.error(e);
                                try {
                                    console.error(e, response.status, await response.text());
                                } catch (e1) {
                                    console.error(e, response.status, e1);
                                }
                                rej(new Error('ошибка ответа сервера'));
                            }

                            if (
                                !response.ok &&
                                attempt < retries &&
                                srvCode !== null &&
                                srvCode !== ApiRespCode.ERR_ANSWERED_RESPONSE
                            ) {
                                retry(attempt, null, response);
                            } else {
                                res({
                                    ...body,
                                    status
                                });
                            }
                        })
                        .catch((error) => {
                            if (attempt < retries) {
                                retry(attempt, error, null);
                            } else {
                                console.error(error);
                                rej(new Error('ошибка сети'));
                            }
                        })
                        .finally(() => {
                            pendingRequests--;
                        });
                } catch (e) {}
            };

            function retry(attempt, error, response): void {
                const delay = typeof retryDelay === 'function' ? retryDelay(attempt, error, response) : retryDelay;
                setTimeout(function () {
                    wrappedFetch(++attempt);
                }, delay);
            }

            wrappedFetch(1);
        });
    };
};
